import React from "react";
import landingImage from "../assets/landing.png";
import process from "../assets/process.svg";
import {
  CloudIcon,
  PeopleIcon,
  LinkIcon,
  ShieldCheckIcon,
  GraphIcon,
  RocketIcon,
  ZapIcon,
} from "@primer/octicons-react";
import MainLayout from "../layouts/MainLayout";

const BANNER_ITEMS = [
  {
    icon: CloudIcon,
    title: "Cloud-Based",
    description: "High availability, efficiency, and scalability",
  },
  {
    icon: LinkIcon,
    title: "Integrated approach",
    description: "Seamlessly bridge Design, Manufacturing, and Quality",
  },
  {
    icon: PeopleIcon,
    title: "Connect your Team",
    description: "Simplify workstreams, share anytime anywhere",
  },
];

const CHOOSE_US_ITEMS = [
  {
    icon: RocketIcon,
    title: "Efficiency",
    description:
      "Our product is engineered for maximum efficiency and usability, enabling you to accomplish more in less time.",
  },
  {
    icon: ShieldCheckIcon,
    title: "Compliance",
    description:
      "Our product ensures full operational compliance with ISO 13485 and 21 CFR Part 820, enabling you to be audit ready anytime.",
  },
  {
    icon: ZapIcon,
    title: "Reliability",
    description:
      "With a track record of excellence, we pride ourselves on delivering reliable solutions that you can count on day in and day out",
  },
  {
    icon: GraphIcon,
    title: "Scalability",
    description:
      "As your business grows, our product scales with you, ensuring that it remains a perfect fit for your evolving needs.",
  },
];

const HomePage: React.FC = () => {
  return (
    <MainLayout>
      {/* HERO SECTION */}
      <div className="flex flex-col lg:flex-row justify-between items-center p-4 md:p-10 gap-4">
        <div className="w-full lg:w-2/5 mb-10 lg:mb-0">
          <h1 className="text-3xl md:text-5xl font-bold text-gray-900 mt-4 leading-tight md:leading-[3.5rem]">
            Design Transfer and <br className="hidden md:inline" />{" "}
            Manufacturing Execution
          </h1>
          <p className="text-gray-700 mt-4">
            Maximize your Medical Device core competency
          </p>
          <div className="mt-6 flex flex-col sm:flex-row">
            <a
              href="/contact"
              className="bg-[#0274bf] border-none hover:bg-[#00548c] text-white px-6 py-2 rounded-lg mb-4 sm:mb-0 sm:mr-4 w-full sm:w-auto"
            >
              Contact Us
            </a>
            <a
              href="/product"
              className="border-solid border-2 border-[#0274bf] text-[#00548c] hover:bg-[#0274bf] hover:text-white px-6 py-2 rounded-lg w-full sm:w-auto"
            >
              Learn More
            </a>
          </div>
        </div>
        <div className="w-full lg:w-3/5">
          <div className="relative">
            <img className="w-full" src={landingImage} alt="Screenshot" />
          </div>
        </div>
      </div>

      {/* BANNER */}
      <div className="bg-[#0274bf] rounded-3xl px-4 md:px-8 py-8 md:py-16 flex flex-col md:flex-row justify-around items-center mt-6 space-y-8 md:space-y-0">
        {BANNER_ITEMS.map((item, index) => (
          <div key={index} className="text-center w-full md:w-1/3 px-4">
            <item.icon size={36} className="text-white mb-1 inline-block" />
            <h3 className="font-bold mb-1 text-xl text-white">{item.title}</h3>
            <p className="text-gray-200">{item.description}</p>
          </div>
        ))}
      </div>

      {/* ABOUT US */}
      <div className="flex flex-col lg:flex-row justify-center items-center mt-20 lg:mt-40 px-4 md:px-10">
        <div className="w-full lg:w-1/2 flex justify-center items-center mb-8 lg:mb-0">
          <img
            src={process}
            alt="Technician cleaning a chair with a vacuum cleaner"
            className="max-w-full h-auto"
          />
        </div>
        <div className="w-full lg:w-1/2 lg:pl-10 mt-10">
          <h1 className="text-3xl font-bold mb-6">ABOUT US</h1>
          <p className="text-gray-700 mb-4">
            At Solutions Unified, we measure our success by yours. Our mission
            is to empower you to reach new levels of achievement by providing
            innovative solutions that drive tangible results. With our product
            by your side, you can confidently execute your business, knowing
            that you have a trusted partner on your side every step of the way.
          </p>
          <p className="text-gray-700 mb-4">
            We believe that your time is best spent focusing on your core
            competency: providing the best medical devices to help patients
            around the world. That's why we've developed an innovative product
            designed to streamline your design transfer and manufacturing
            execution workflows, boost compliance and productivity, and empower
            you to excel in what you do best.
          </p>
        </div>
      </div>

      {/* WHY CHOOSE US */}
      <div className="mt-20 px-4 md:px-12">
        <div className="bg-[#0274bf] py-12 rounded-xl flex justify-center mb-16">
          <h3 className="text-2xl md:text-3xl font-bold text-white">
            Why choose us?
          </h3>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 -mt-24 lg:px-8 md:px-4">
          {CHOOSE_US_ITEMS.map((item, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
              <div className="flex items-center mb-2">
                <item.icon size={24} className="text-[#0274bf] mr-2" />
                <h3 className="text-lg font-semibold">{item.title}</h3>
              </div>
              <p className="text-gray-600">{item.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* CONTACT US */}
      <div className="flex flex-col justify-center items-center my-20 md:my-40 bg-[#EBF3FA] px-4 md:px-32 py-12 md:py-20 rounded-xl mx-4 md:mx-0">
        <h1 className="text-2xl md:text-3xl font-bold mb-6 text-center">
          Get Started Today
        </h1>
        <p className="text-gray-700 text-center">
          Ready to experience the difference that our solutions can make for
          your business? Contact us today to schedule a demo, start your 30-day
          free trial, or learn more about how we can help you.{" "}
          <br className="hidden md:inline" /> Let's work together to elevate
          your success and maximize your core competency
        </p>
      </div>
    </MainLayout>
  );
};

export default HomePage;
