import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { directory } from "../util/directory";
import banner from "../assets/banner.png";
import Copyright from "../components/Copyright";

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const location = useLocation();

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <header className="p-5 border-b border-gray-300">
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-end space-x-4">
            <a href="/" className="text-2xl font-medium">
              <img src={banner} alt="Banner" className="h-8" />
            </a>
          </div>
          <div className="flex items-end space-x-4">
            {directory.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.path}
                className={({ isActive }) =>
                  isActive
                    ? "text-blue-500 border-b-2 border-blue-500 text-lg font-medium"
                    : "text-black text-lg hover:text-blue-500 transition duration-300 font-medium"
                }
              >
                {tab.name}
              </NavLink>
            ))}
          </div>
        </div>
      </header>
      <main className="flex-grow bg-white">
        <div className="container mx-auto p-5">{children}</div>
      </main>
      <footer className="p-5 bg-slate-800 text-white text-sm">
        <div className="container mx-auto text-center">
          <Copyright />
          {location.pathname !== "/legal-documents" && (
            <div className="flex justify-center items-center space-x-4 my-5">
              <a href="/privacy-policy" className="hover:underline">
                Privacy Policy
              </a>
              <a href="/terms-of-service" className="hover:underline">
                Terms of Service
              </a>
              <a href="/cookie-policy" className="hover:underline">
                Cookie Policy
              </a>
            </div>
          )}
        </div>
      </footer>
    </div>
  );
};

export default MainLayout;
