import React from "react";
import MainLayout from "../layouts/MainLayout";

const PrivacyPolicyPage: React.FC = () => {
  return (
    <MainLayout>
      <div className="flex flex-col gap-4">
        <div className="text-2xl font-bold">Privacy Policy</div>
        <div>Last updated: January 22, 2024</div>
        <div>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </div>
        <div>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </div>
        <div className="text-xl font-bold">Interpretation and Definitions</div>
        <div className="ms-2 text-lg font-bold">Interpretation</div>
        <div className="ms-2">
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </div>
        <div className="ms-2 text-lg font-bold">Definitions</div>
        <div className="ms-2">For the purposes of this Privacy Policy:</div>
        <div className="ms-4 flex flex-col gap-2">
          <div>
            <strong>Account</strong> means a unique account created for You to
            access our Service or parts of our Service.
          </div>
          <div>
            <strong>Affiliate</strong> means an entity that controls, is
            controlled by or is under common control with a party, where
            &quot;control&quot; means ownership of 50% or more of the shares,
            equity interest or other securities entitled to vote for election of
            directors or other managing authority.
          </div>
          <div>
            <strong>Application</strong> refers to any application offered by
            Solutions Unified.
          </div>
          <div>
            <strong>Business</strong>, for the purpose of CCPA/CPRA, refers to
            the Company as the legal entity that collects Consumers' personal
            information and determines the purposes and means of the processing
            of Consumers' personal information, or on behalf of which such
            information is collected and that alone, or jointly with others,
            determines the purposes and means of the processing of consumers'
            personal information, that does business in the State of California.
          </div>
          <div>
            <strong>CCPA</strong> and/or <strong>CPRA</strong> refers to the
            California Consumer Privacy Act (the &quot;CCPA&quot;) as amended by
            the California Privacy Rights Act of 2020 (the &quot;CPRA&quot;).
          </div>
          <div>
            <strong>Company</strong> (referred to as either &quot;the
            Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
            this Agreement) refers to Solutions Unified LLC, 1 Orchard St E
            Califon NJ 07830.
          </div>
          <div>
            For the purpose of the GDPR, the Company is the Data Controller.
          </div>
          <div>
            <strong>Consumer</strong>, for the purpose of the CCPA/CPRA, means a
            natural person who is a California resident. A resident, as defined
            in the law, includes (1) every individual who is in the USA for
            other than a temporary or transitory purpose, and (2) every
            individual who is domiciled in the USA who is outside the USA for a
            temporary or transitory purpose.
          </div>
          <div>
            <strong>Country</strong> refers to: New Jersey, United States
          </div>
          <div>
            <strong>Data Controller</strong>, for the purposes of the GDPR
            (General Data Protection Regulation), refers to the Company as the
            legal person which alone or jointly with others determines the
            purposes and means of the processing of Personal Data.
          </div>
          <div>
            <strong>Device</strong> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </div>
          <div>
            <strong>Do Not Track</strong> (DNT) is a concept that has been
            promoted by US regulatory authorities, in particular the U.S.
            Federal Trade Commission (FTC), for the Internet industry to develop
            and implement a mechanism for allowing internet users to control the
            tracking of their online activities across websites.
          </div>
          <div>
            <strong>GDPR</strong> refers to EU General Data Protection
            Regulation.
          </div>
          <div>
            <strong>Personal Data</strong> is any information that relates to an
            identified or identifiable individual.
          </div>
          <div>
            For the purposes of GDPR, Personal Data means any information
            relating to You such as a name, an identification number, location
            data, online identifier or to one or more factors specific to the
            physical, physiological, genetic, mental, economic, cultural or
            social identity.
          </div>
          <div>
            For the purposes of the CCPA/CPRA, Personal Data means any
            information that identifies, relates to, describes or is capable of
            being associated with, or could reasonably be linked, directly or
            indirectly, with You.
          </div>
          <div>
            <strong>Service</strong> refers to the Application.
          </div>
          <div>
            <strong>Service Provider</strong> means any natural or legal person
            who processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used. For the purpose of the
            GDPR, Service Providers are considered Data Processors.
          </div>
          <div>
            <strong>Usage Data</strong> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </div>
          <div>
            <strong>You</strong> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </div>
          <div>
            Under GDPR, You can be referred to as the Data Subject or as the
            User as you are the individual using the Service.
          </div>
        </div>
        <div className="text-xl font-bold">
          Collecting and Using Your Personal Data
        </div>
        <div className="ms-2 text-lg font-bold">Types of Data Collected</div>
        <div className="ms-4 font-bold">Personal Data</div>
        <div className="ms-4">
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </div>
        <div className="ms-4">First name</div>
        <div className="ms-4">Last name</div>
        <div className="ms-4">usernames</div>
        <div className="ms-4">passwords</div>
        <div className="ms-4">contact preferences</div>
        <div className="ms-4">contact or authentication data</div>
        <div className="ms-4">billing information, e.g.:</div>
        <div className="ms-6">addresses</div>
        <div className="ms-6">debit/credit card information</div>
        <div className="ms-6">banking information</div>
        <div className="ms-4">
          information you provide when using our products, e.g.:
        </div>
        <div className="ms-6">business name</div>
        <div className="ms-6">business email</div>
        <div className="ms-6">business phone number</div>
        <div className="ms-6">business address</div>
        <div className="ms-6">company website</div>
        <div className="ms-6">job titles</div>
        <div className="ms-4">online survey records and details</div>
        <div className="ms-4">information in blog comments</div>
        <div className="ms-4">website url</div>
        <div className="ms-4">
          interests in our marketing campaigns and products
        </div>
        <div className="ms-4">cookie preferences</div>
        <div className="ms-4 font-bold">Usage Data</div>
        <div className="ms-4">
          Usage Data is collected automatically when using the Service.
        </div>
        <div className="ms-4">
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </div>
        <div className="ms-4">
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </div>
        <div className="ms-4">
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </div>
        <div className="ms-2 text-lg font-bold">Use of Your Personal Data</div>
        <div className="ms-2">
          The Company may use Personal Data for the following purposes:
        </div>
        <div className="ms-4 flex flex-col gap-2">
          <div>
            <strong>To provide and maintain our Service</strong>, including to
            monitor the usage of our Service.
          </div>
          <div>
            <strong>To manage Your Account:</strong> to manage Your registration
            as a user of the Service. The Personal Data You provide can give You
            access to different functionalities of the Service that are
            available to You as a registered user.
          </div>
          <div>
            <strong>For the performance of a contract:</strong> the development,
            compliance and undertaking of the purchase contract for the
            products, items or services You have purchased or of any other
            contract with Us through the Service.
          </div>
          <div>
            <strong>To contact You:</strong> To contact You by email, telephone
            calls, SMS, or other equivalent forms of electronic communication,
            such as a mobile application's push notifications regarding updates
            or informative communications related to the functionalities,
            products or contracted services, including the security updates,
            when necessary or reasonable for their implementation.
          </div>
          <div>
            <strong>To provide You</strong> with news, special offers and
            general information about other goods, services and events which we
            offer that are similar to those that you have already purchased or
            enquired about unless You have opted not to receive such
            information.
          </div>
          <div>
            <strong>To manage Your requests:</strong> To attend and manage Your
            requests to Us.
          </div>
          <div>
            <strong>For business transfers:</strong> We may use Your information
            to evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of Our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which Personal
            Data held by Us about our Service users is among the assets
            transferred.
          </div>
          <div>
            <strong>For other purposes</strong>: We may use Your information for
            other purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Service, products, services, marketing and
            your experience.
          </div>
        </div>
        <div className="ms-2">
          We may share Your personal information in the following situations:
        </div>
        <div className="ms-4 flex flex-col gap-2">
          <div>
            <strong>With Service Providers:</strong> We may share Your personal
            information with Service Providers to monitor and analyze the use of
            our Service, to contact You.
          </div>
          <div>
            <strong>For business transfers:</strong> We may share or transfer
            Your personal information in connection with, or during negotiations
            of, any merger, sale of Company assets, financing, or acquisition of
            all or a portion of Our business to another company.
          </div>
          <div>
            <strong>With Affiliates:</strong> We may share Your information with
            Our affiliates, in which case we will require those affiliates to
            honor this Privacy Policy. Affiliates include Our parent company and
            any other subsidiaries, joint venture partners or other companies
            that We control or that are under common control with Us.
          </div>
          <div>
            <strong>With business partners:</strong> We may share Your
            information with Our business partners to offer You certain
            products, services or promotions.
          </div>
          <div>
            <strong>With other users:</strong> when You share personal
            information or otherwise interact in the public areas with other
            users, such information may be viewed by all users and may be
            publicly distributed outside.
          </div>
          <div>
            <strong>With Your consent</strong>: We may disclose Your personal
            information for any other purpose with Your consent.
          </div>
        </div>
        <div className="ms-2 text-lg font-bold">
          Retention of Your Personal Data
        </div>
        <div className="ms-2">
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </div>
        <div className="ms-2">
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </div>
        <div className="ms-2 text-lg font-bold">
          Transfer of Your Personal Data
        </div>
        <div className="ms-2">
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </div>
        <div className="ms-2">
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </div>
        <div className="ms-2">
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </div>
        <div className="ms-2 text-lg font-bold">Delete Your Personal Data</div>
        <div className="ms-2">
          You have the right to delete or request that We assist in deleting the
          Personal Data that We have collected about You.
        </div>
        <div className="ms-2">
          Our Service may give You the ability to delete certain information
          about You from within the Service.
        </div>
        <div className="ms-2">
          You may update, amend, or delete Your information at any time by
          signing in to Your Account, if you have one, and visiting the account
          settings section that allows you to manage Your personal information.
          You may also contact Us to request access to, correct, or delete any
          personal information that You have provided to Us.
        </div>
        <div className="ms-2">
          Please note, however, that We may need to retain certain information
          when we have a legal obligation or lawful basis to do so.
        </div>
        <div className="ms-2 text-lg font-bold">
          Disclosure of Your Personal Data
        </div>
        <div className="ms-4 font-bold">Business Transactions</div>
        <div className="ms-4">
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </div>
        <div className="ms-4 font-bold">Law enforcement</div>
        <div className="ms-4">
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </div>
        <div className="ms-4 font-bold">Other legal requirements</div>
        <div className="ms-4">
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </div>
        <div className="ms-4">
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
        </div>
        <div className="ms-2 text-lg font-bold">
          Security of Your Personal Data
        </div>
        <div className="ms-2">
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </div>
        <div className="text-xl font-bold">GDPR Privacy</div>
        <div className="ms-2 text-lg font-bold">
          Legal Basis for Processing Personal Data under GDPR
        </div>
        <div className="ms-4">
          We may process Personal Data under the following conditions:
        </div>
        <div className="ms-4 flex flex-col gap-2">
          <div>
            <strong>Consent:</strong> You have given Your consent for processing
            Personal Data for one or more specific purposes.
          </div>
          <div>
            <strong>Performance of a contract:</strong> Provision of Personal
            Data is necessary for the performance of an agreement with You
            and/or for any pre-contractual obligations thereof.
          </div>
          <div>
            <strong>Legal obligations:</strong> Processing Personal Data is
            necessary for compliance with a legal obligation to which the
            Company is subject.
          </div>
          <div>
            <strong>Vital interests:</strong> Processing Personal Data is
            necessary in order to protect Your vital interests or of another
            natural person.
          </div>
          <div>
            <strong>Public interests:</strong> Processing Personal Data is
            related to a task that is carried out in the public interest or in
            the exercise of official authority vested in the Company.
          </div>
          <div>
            <strong>Legitimate interests:</strong> Processing Personal Data is
            necessary for the purposes of the legitimate interests pursued by
            the Company.
          </div>
        </div>
        <div className="ms-4">
          In any case, the Company will gladly help to clarify the specific
          legal basis that applies to the processing, and in particular whether
          the provision of Personal Data is a statutory or contractual
          requirement, or a requirement necessary to enter into a contract.
        </div>
        <div className="ms-2 text-lg font-bold">Your Rights under the GDPR</div>
        <div className="ms-2">
          The Company undertakes to respect the confidentiality of Your Personal
          Data and to guarantee You can exercise Your rights.
        </div>
        <div className="ms-2">
          You have the right under this Privacy Policy, and by law if You are
          within the EU, to:
        </div>
        <div className="ms-4 flex flex-col gap-2">
          <div>
            <strong>Request access to Your Personal Data.</strong> The right to
            access, update or delete the information We have on You. Whenever
            made possible, you can access, update or request deletion of Your
            Personal Data directly within Your account settings section. If you
            are unable to perform these actions yourself, please contact Us to
            assist You. This also enables You to receive a copy of the Personal
            Data We hold about You.
          </div>
          <div>
            <strong>
              Request correction of the Personal Data that We hold about You.
            </strong>{" "}
            You have the right to have any incomplete or inaccurate information
            We hold about You corrected.
          </div>
          <div>
            <strong>Object to processing of Your Personal Data.</strong> This
            right exists where We are relying on a legitimate interest as the
            legal basis for Our processing and there is something about Your
            particular situation, which makes You want to object to our
            processing of Your Personal Data on this ground. You also have the
            right to object where We are processing Your Personal Data for
            direct marketing purposes.
          </div>
          <div>
            <strong>Request erasure of Your Personal Data.</strong> You have the
            right to ask Us to delete or remove Personal Data when there is no
            good reason for Us to continue processing it.
          </div>
          <div>
            <strong>Request the transfer of Your Personal Data.</strong> We will
            provide to You, or to a third-party You have chosen, Your Personal
            Data in a structured, commonly used, machine-readable format. Please
            note that this right only applies to automated information which You
            initially provided consent for Us to use or where We used the
            information to perform a contract with You.
          </div>
          <div>
            <strong>Withdraw Your consent.</strong> You have the right to
            withdraw Your consent on using your Personal Data. If You withdraw
            Your consent, We may not be able to provide You with access to
            certain specific functionalities of the Service.
          </div>
        </div>
        <div className="ms-2 text-lg font-bold">
          Exercising of Your GDPR Data Protection Rights
        </div>
        <div className="ms-2">
          You may exercise Your rights of access, rectification, cancellation
          and opposition by contacting Us. Please note that we may ask You to
          verify Your identity before responding to such requests. If You make a
          request, We will try our best to respond to You as soon as possible.
        </div>
        <div className="ms-2">
          You have the right to complain to a Data Protection Authority about
          Our collection and use of Your Personal Data. For more information, if
          You are in the European Economic Area (EEA), please contact Your local
          data protection authority in the EEA.
        </div>
        <div className="ms-2 text-lg font-bold">CCPA/CPRA Privacy Notice</div>
        <div className="ms-2">
          This privacy notice section for California residents supplements the
          information contained in Our Privacy Policy and it applies solely to
          all visitors, users, and others who reside in the State of California.
        </div>
        <div className="ms-2 text-lg font-bold">
          Categories of Personal Information Collected
        </div>
        <div className="ms-2">
          We collect information that identifies, relates to, describes,
          references, is capable of being associated with, or could reasonably
          be linked, directly or indirectly, with a particular Consumer or
          Device. The following is a list of categories of personal information
          which we may collect or may have been collected from California
          residents within the last twelve (12) months.
        </div>
        <div className="ms-2">
          Please note that the categories and examples provided in the list
          below are those defined in the CCPA/CPRA. This does not mean that all
          examples of that category of personal information were in fact
          collected by Us, but reflects our good faith belief to the best of Our
          knowledge that some of that information from the applicable category
          may be and may have been collected. For example, certain categories of
          personal information would only be collected if You provided such
          personal information directly to Us.
        </div>
        <div className="ms-4 flex flex-col gap-2">
          <div>
            <strong>Category A: Identifiers.</strong>
          </div>
          <div>
            Examples: A real name, alias, business address, unique personal
            identifier, online identifier, Internet Protocol address, email
            address, account name, or other similar identifiers.
          </div>
          <div>Collected: Yes.</div>
          <div>
            <strong>
              Category B: Personal information categories listed in the
              California Customer Records statute (Cal. Civ. Code § 1798.80(e)).
            </strong>
          </div>
          <div>
            Examples: A name, signature, physical characteristics or
            description, business address, business telephone number,
            employment, business bank account number, business credit card
            number, business debit card number, or any other financial business
            information. Some personal information included in this category may
            overlap with other categories.
          </div>
          <div>Collected: Yes.</div>
          <div>
            <strong>
              Category C: Protected classification characteristics under
              California or federal law.
            </strong>
          </div>
          <div>
            Examples: Age (40 years or older), race, color, ancestry, national
            origin, citizenship, religion or creed, marital status, medical
            condition, physical or mental disability, sex (including gender,
            gender identity, gender expression, pregnancy or childbirth and
            related medical conditions), sexual orientation, veteran or military
            status, genetic information (including familial genetic
            information).
          </div>
          <div>Collected: No.</div>
          <div>
            <strong>Category D: Commercial information.</strong>
          </div>
          <div>
            Examples: Records and history of products or services purchased or
            considered.
          </div>
          <div>Collected: No.</div>
          <div>
            <strong>Category E: Biometric information.</strong>
          </div>
          <div>
            Examples: Genetic, physiological, behavioral, and biological
            characteristics, or activity patterns used to extract a template or
            other identifier or identifying information, such as, fingerprints,
            faceprints, and voiceprints, iris or retina scans, keystroke, gait,
            or other physical patterns, and sleep, health, or exercise data.
          </div>
          <div>Collected: No.</div>
          <div>
            <strong>
              Category F: Internet or other similar network activity.
            </strong>
          </div>
          <div>Examples: Interaction with our Service or advertisement.</div>
          <div>Collected: Yes.</div>
          <div>
            <strong>Category G: Geolocation data.</strong>
          </div>
          <div>Examples: Approximate physical location.</div>
          <div>Collected: No.</div>
          <div>
            <strong>Category H: Sensory data.</strong>
          </div>
          <div>
            Examples: Audio, electronic, visual, thermal, olfactory, or similar
            information.
          </div>
          <div>Collected: No.</div>
          <div>
            <strong>
              Category I: Professional or employment-related information.
            </strong>
          </div>
          <div>
            Examples: Current or past job history or performance evaluations.
          </div>
          <div>Collected: No.</div>
          <div>
            <strong>
              Category J: Non-public education information (per the Family
              Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34
              C.F.R. Part 99)).
            </strong>
          </div>
          <div>
            Examples: Education records directly related to a student maintained
            by an educational institution or party acting on its behalf, such as
            grades, transcripts, class lists, student schedules, student
            identification codes, student financial information, or student
            disciplinary records.
          </div>
          <div>Collected: No.</div>
          <div>
            <strong>
              Category K: Inferences drawn from other personal information.
            </strong>
          </div>
          <div>
            Examples: Profile reflecting a person's preferences,
            characteristics, psychological trends, predispositions, behavior,
            attitudes, intelligence, abilities, and aptitudes.
          </div>
          <div>Collected: No.</div>
          <div>
            <strong>Category L: Sensitive personal information.</strong>
          </div>
          <div>
            Examples: Account login and password information, geolocation data.
          </div>
          <div>Collected: Yes.</div>
        </div>
        <div className="ms-2">
          Under CCPA/CPRA, personal information does not include:
        </div>
        <div className="ms-4 flex flex-col gap-2">
          <div>Publicly available information from government records</div>
          <div>Deidentified or aggregated consumer information</div>
          <div>
            Information excluded from the CCPA/CPRA's scope, such as:
            <div className="ms-4">
              <li>
                Health or medical information covered by the Health Insurance
                Portability and Accountability Act of 1996 (HIPAA) and the
                California Confidentiality of Medical Information Act (CMIA) or
                clinical trial data
              </li>
              <li>
                Personal Information covered by certain sector-specific privacy
                laws, including the Fair Credit Reporting Act (FRCA), the
                Gramm-Leach-Bliley Act (GLBA) or California Financial
                Information Privacy Act (FIPA), and the Driver's Privacy
                Protection Act of 1994
              </li>
            </div>
          </div>
        </div>
        <div className="ms-2 text-lg font-bold">
          Sources of Personal Information
        </div>
        <div className="ms-2">
          We obtain the categories of personal information listed above from the
          following categories of sources:
        </div>
        <div className="ms-4 flex flex-col gap-2">
          <div>
            <strong>Directly from You</strong>. For example, from the forms You
            complete on our Service, preferences You express or provide through
            our Service.
          </div>
          <div>
            <strong>Indirectly from You</strong>. For example, from observing
            Your activity on our Service.
          </div>
          <div>
            <strong>Automatically from You</strong>. For example, through
            cookies We or our Service Providers set on Your Device as You
            navigate through our Service.
          </div>
          <div>
            <strong>From Service Providers</strong>. For example, or other
            third-party vendors that We use to provide the Service to You.
          </div>
        </div>
        <div className="ms-2 text-lg font-bold">
          Use of Personal Information
        </div>
        <div className="ms-2">
          We may use or disclose personal information We collect for
          &quot;business purposes&quot; or &quot;commercial purposes&quot; (as
          defined under the CCPA/CPRA), which may include the following
          examples:
        </div>
        <div className="ms-4 flex flex-col gap-2">
          <div>To operate our Service and provide You with Our Service.</div>
          <div>
            To provide You with support and to respond to Your inquiries,
            including to investigate and address Your concerns and monitor and
            improve our Service.
          </div>
          <div>
            To fulfill or meet the reason You provided the information. For
            example, if You share Your contact information to ask a question
            about our Service, We will use that personal information to respond
            to Your inquiry.
          </div>
          <div>
            To respond to law enforcement requests and as required by applicable
            law, court order, or governmental regulations.
          </div>
          <div>
            As described to You when collecting Your personal information or as
            otherwise set forth in the CCPA/CPRA.
          </div>
          <div>For internal administrative and auditing purposes.</div>
          <div>
            To detect security incidents and protect against malicious,
            deceptive, fraudulent or illegal activity, including, when
            necessary, to prosecute those responsible for such activities.
          </div>
          <div>Other one-time uses.</div>
        </div>
        <div className="ms-2">
          Please note that the examples provided above are illustrative and not
          intended to be exhaustive. For more details on how we use this
          information, please refer to the &quot;Use of Your Personal Data&quot;
          section.
        </div>
        <div className="ms-2">
          If We decide to collect additional categories of personal information
          or use the personal information We collected for materially different,
          unrelated, or incompatible purposes We will update this Privacy
          Policy.
        </div>
        <div className="ms-2 text-lg font-bold">
          Disclosure of Personal Information
        </div>
        <div className="ms-2">
          We may use or disclose and may have used or disclosed in the last
          twelve (12) months the following categories of personal information
          for business or commercial purposes:
        </div>
        <div className="ms-4 flex flex-col gap-2">
          <div>Category A: Identifiers</div>
          <div>
            Category B: Personal information categories listed in the California
            Customer Records statute (Cal. Civ. Code § 1798.80(e))
          </div>
          <div>Category F: Internet or other similar network activity</div>
        </div>
        <div className="ms-2">
          Please note that the categories listed above are those defined in the
          CCPA/CPRA. This does not mean that all examples of that category of
          personal information were in fact disclosed, but reflects our good
          faith belief to the best of our knowledge that some of that
          information from the applicable category may be and may have been
          disclosed.
        </div>
        <div className="ms-2">
          When We disclose personal information for a business purpose or a
          commercial purpose, We enter a contract that describes the purpose and
          requires the recipient to both keep that personal information
          confidential and not use it for any purpose except performing the
          contract.
        </div>
        <div className="ms-2 text-lg font-bold">
          Share of Personal Information
        </div>
        <div className="ms-2">
          We may share, and have shared in the last twelve (12) months, Your
          personal information identified in the above categories with the
          following categories of third parties:
        </div>
        <div className="ms-4 flex flex-col gap-2">
          <div>Service Providers</div>
          <div>Our affiliates</div>
          <div>Our business partners</div>
          <div>
            Third party vendors to whom You or Your agents authorize Us to
            disclose Your personal information in connection with products or
            services We provide to You
          </div>
        </div>
        <div className="ms-2 text-lg font-bold">
          Sale of Personal Information
        </div>
        <div className="ms-2">
          As defined in the CCPA/CPRA, &quot;sell&quot; and &quot;sale&quot;
          mean selling, renting, releasing, disclosing, disseminating, making
          available, transferring, or otherwise communicating orally, in
          writing, or by electronic or other means, a Consumer's personal
          information by the Business to a third party for valuable
          consideration. This means that We may have received some kind of
          benefit in return for sharing personal information, but not
          necessarily a monetary benefit.
        </div>
        <div className="ms-2">
          We do not sell personal information as the term sell is commonly
          understood. We do allow Service Providers to use Your personal
          information for the business purposes described in Our Privacy Policy,
          and these may be deemed a sale under CCPA/CPRA.
        </div>
        <div className="ms-2">
          We may sell and may have sold in the last twelve (12) months the
          following categories of personal information:
        </div>
        <div className="ms-4 flex flex-col gap-2">
          <div>Category A: Identifiers</div>
          <div>
            Category B: Personal information categories listed in the California
            Customer Records statute (Cal. Civ. Code § 1798.80(e))
          </div>
          <div>Category F: Internet or other similar network activity</div>
        </div>
        <div className="ms-2">
          Please note that the categories listed above are those defined in the
          CCPA/CPRA. This does not mean that all examples of that category of
          personal information were in fact sold, but reflects our good faith
          belief to the best of Our knowledge that some of that information from
          the applicable category may be and may have been shared for value in
          return.
        </div>
        <div className="ms-2 text-lg font-bold">
          Sale of Personal Information of Minors Under 16 Years of Age
        </div>
        <div className="ms-2">
          We do not knowingly collect personal information from minors under the
          age of 16 through our Service.
        </div>
        <div className="ms-2">
          We do not sell the personal information of Consumers We actually know
          are less than 16 years of age.
        </div>
        <div className="ms-2">
          If You have reason to believe that a child under the age of 13 (or 16)
          has provided Us with personal information, please contact Us with
          sufficient detail to enable Us to delete that information.
        </div>
        <div className="ms-2 text-lg font-bold">
          Your Rights under the CCPA/CPRA
        </div>
        <div className="ms-2">
          The CCPA/CPRA provides California residents with specific rights
          regarding their personal information. If You are a resident of
          California, You have the following rights:
        </div>
        <div className="ms-4 flex flex-col gap-2">
          <div>
            <strong>The right to notice.</strong> You have the right to be
            notified which categories of Personal Data are being collected and
            the purposes for which the Personal Data is being used.
          </div>
          <div>
            <strong>The right to know/access.</strong> Under CCPA/CPRA, You have
            the right to request that We disclose information to You about Our
            collection, use, sale, disclosure for business purposes and share of
            personal information. Once We receive and confirm Your request, We
            will disclose to You:
            <div className="ms-6 flex flex-col gap-2">
              <li>
                The categories of personal information We collected about You
              </li>
              <li>
                The categories of sources for the personal information We
                collected about You
              </li>
              <li>
                Our business or commercial purposes for collecting or selling
                that personal information
              </li>
              <li>
                The categories of third parties with whom We share that personal
                information
              </li>
              <li>
                The specific pieces of personal information We collected about
                You
              </li>
              <li>
                If we sold Your personal information or disclosed Your personal
                information for a business purpose, We will disclose to You:
                <div className="ms-8 flex flex-col gap-2">
                  <li>
                    The categories of personal information categories sold
                  </li>
                  <li>
                    The categories of personal information categories disclosed
                  </li>
                </div>
              </li>
            </div>
          </div>
          <div>
            <strong>
              The right to say no to the sale or sharing of Personal Data
              (opt-out).
            </strong>{" "}
            You have the right to direct Us to not sell Your personal
            information. To submit an opt-out request, please see the &quot;Do
            Not Sell My Personal Information&quot; section or contact Us.
          </div>
          <div>
            <strong>The right to correct Personal Data.</strong> You have the
            right to correct or rectify any inaccurate personal information
            about You that We collected. Once We receive and confirm Your
            request, We will use commercially reasonable efforts to correct (and
            direct our Service Providers to correct) Your personal information,
            unless an exception applies.
          </div>
          <div>
            <strong>
              The right to limit use and disclosure of sensitive Personal Data.
            </strong>{" "}
            You have the right to request to limit the use or disclosure of
            certain sensitive personal information We collected about You,
            unless an exception applies. To submit, please see the &quot;Limit
            the Use or Disclosure of My Sensitive Personal Information&quot;
            section or contact Us.
          </div>
          <div>
            <strong>The right to delete Personal Data.</strong> You have the
            right to request the deletion of Your Personal Data under certain
            circumstances, subject to certain exceptions. Once We receive and
            confirm Your request, We will delete (and direct Our Service
            Providers to delete) Your personal information from our records,
            unless an exception applies. We may deny Your deletion request if
            retaining the information is necessary for Us or Our Service
            Providers to:
            <div className="ms-6 flex flex-col gap-2">
              <li>
                Complete the transaction for which We collected the personal
                information, provide a good or service that You requested, take
                actions reasonably anticipated within the context of our ongoing
                business relationship with You, or otherwise perform our
                contract with You.
              </li>
              <li>
                Detect security incidents, protect against malicious, deceptive,
                fraudulent, or illegal activity, or prosecute those responsible
                for such activities.
              </li>
              <li>
                Debug products to identify and repair errors that impair
                existing intended functionality.
              </li>
              <li>
                Exercise free speech, ensure the right of another consumer to
                exercise their free speech rights, or exercise another right
                provided for by law.
              </li>
              <li>
                Comply with the California Electronic Communications Privacy Act
                (Cal. Penal Code § 1546 et. seq.).
              </li>
              <li>
                Engage in public or peer-reviewed scientific, historical, or
                statistical research in the public interest that adheres to all
                other applicable ethics and privacy laws, when the information's
                deletion may likely render impossible or seriously impair the
                research's achievement, if You previously provided informed
                consent.
              </li>
              <li>
                Enable solely internal uses that are reasonably aligned with
                consumer expectations based on Your relationship with Us.
              </li>
              <li>Comply with a legal obligation.</li>
              <li>
                Make other internal and lawful uses of that information that are
                compatible with the context in which You provided it.
              </li>
            </div>
          </div>
          <div>
            <strong>The right not to be discriminated against.</strong> You have
            the right not to be discriminated against for exercising any of Your
            consumer's rights, including by:
            <div className="ms-6 flex flex-col gap-2">
              <li>Denying goods or services to You</li>
              <li>
                Charging different prices or rates for goods or services,
                including the use of discounts or other benefits or imposing
                penalties
              </li>
              <li>
                Providing a different level or quality of goods or services to
                You
              </li>
              <li>
                Suggesting that You will receive a different price or rate for
                goods or services or a different level or quality of goods or
                services
              </li>
            </div>
          </div>
        </div>
        <div className="ms-2 text-lg font-bold">
          Exercising Your CCPA/CPRA Data Protection Rights
        </div>
        <div className="ms-2">
          Please see the &quot;Do Not Sell My Personal Information&quot; section
          and &quot;Limit the Use or Disclosure of My Sensitive Personal
          Information&quot; section for more information on how to opt out and
          limit the use of sensitive information collected.
        </div>
        <div className="ms-2">
          Additionally, in order to exercise any of Your rights under the
          CCPA/CPRA, and if You are a California resident, You can contact Us:
        </div>
        <div className="ms-2">
          <li>By email: info@solutionsunified.com</li>
        </div>
        <div className="ms-2">
          Only You, or a person registered with the California Secretary of
          State that You authorize to act on Your behalf, may make a verifiable
          request related to Your personal information.
        </div>
        <div className="ms-2">Your request to Us must:</div>
        <div className="ms-4 flex flex-col gap-2">
          <div>
            Provide sufficient information that allows Us to reasonably verify
            You are the person about whom We collected personal information or
            an authorized representative
          </div>
          <div>
            Describe Your request with sufficient detail that allows Us to
            properly understand, evaluate, and respond to it
          </div>
        </div>
        <div className="ms-2">
          We cannot respond to Your request or provide You with the required
          information if We cannot:
        </div>
        <div className="ms-2">
          <li>Verify Your identity or authority to make the request</li>
          <li>And confirm that the personal information relates to You</li>
        </div>
        <div className="ms-2">
          We will disclose and deliver the required information free of charge
          within 45 days of receiving Your verifiable request. The time period
          to provide the required information may be extended once by an
          additional 45 days when reasonably necessary and with prior notice.
        </div>
        <div className="ms-2">
          Any disclosures We provide will only cover the 12-month period
          preceding the verifiable request's receipt.
        </div>
        <div className="ms-2">
          For data portability requests, We will select a format to provide Your
          personal information that is readily usable and should allow You to
          transmit the information from one entity to another entity without
          hindrance.
        </div>
        <div className="ms-2 text-lg font-bold">
          Do Not Sell My Personal Information
        </div>
        <div className="ms-2">
          As defined in the CCPA/CPRA, &quot;sell&quot; and &quot;sale&quot;
          mean selling, renting, releasing, disclosing, disseminating, making
          available, transferring, or otherwise communicating orally, in
          writing, or by electronic or other means, a Consumer's personal
          information by the Business to a third party for valuable
          consideration. This means that We may have received some kind of
          benefit in return for sharing personal information, but not
          necessarily a monetary benefit.
        </div>
        <div className="ms-2">
          We do not sell personal information as the term sell is commonly
          understood. We do allow Service Providers to use Your personal
          information for the business purposes described in Our Privacy Policy,
          and these may be deemed a sale under CCPA/CPRA.
        </div>
        <div className="ms-2">
          You have the right to opt-out of the sale of Your personal
          information. Once We receive and confirm a verifiable consumer request
          from You, we will stop selling Your personal information. To exercise
          Your right to opt-out, please contact Us.
        </div>
        <div className="ms-2 text-lg font-bold">
          Limit the Use or Disclosure of My Sensitive Personal Information
        </div>
        <div className="ms-2">
          If You are a California resident, You have the right to limit the use
          and disclosure of Your sensitive personal information to that use
          which is necessary to perform the services or provide the goods
          reasonably expected by an average Consumer who requests such services
          or goods.
        </div>
        <div className="ms-2">
          We collect, use and disclose sensitive personal information in ways
          that are necessary to provide the Service. For more information on how
          We use Your personal information, please see the &quot;Use of Your
          Personal Data&quot; section or contact us.
        </div>
        <div className="text-xl font-bold">
          &quot;Do Not Track&quot; Policy as Required by California Online
          Privacy Protection Act (CalOPPA)
        </div>
        <div className="ms-2">
          Our Service does not respond to Do Not Track signals.
        </div>
        <div className="ms-2">
          However, some third party websites do keep track of Your browsing
          activities. If You are visiting such websites, You can set Your
          preferences in Your web browser to inform websites that You do not
          want to be tracked. You can enable or disable DNT by visiting the
          preferences or settings page of Your web browser.
        </div>
        <div className="text-xl font-bold">
          Your California Privacy Rights (California's Shine the Light law)
        </div>
        <div className="ms-2">
          Under California Civil Code Section 1798 (California's Shine the Light
          law), California residents with an established business relationship
          with us can request information once a year about sharing their
          Personal Data with third parties for the third parties' direct
          marketing purposes.
        </div>
        <div className="ms-2">
          If you'd like to request more information under the California Shine
          the Light law, and if You are a California resident, You can contact
          Us using the contact information provided below.
        </div>
        <div className="text-xl font-bold">
          California Privacy Rights for Minor Users (California Business and
          Professions Code Section 22581)
        </div>
        <div className="ms-2">
          California Business and Professions Code Section 22581 allows
          California residents under the age of 18 who are registered users of
          online sites, services or applications to request and obtain removal
          of content or information they have publicly posted.
        </div>
        <div className="ms-2">
          To request removal of such data, and if You are a California resident,
          You can contact Us using the contact information provided below, and
          include the email address associated with Your account.
        </div>
        <div className="ms-2">
          Be aware that Your request does not guarantee complete or
          comprehensive removal of content or information posted online and that
          the law may not permit or require removal in certain circumstances.
        </div>
        <div className="ms-2">
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, We take
          steps to remove that information from Our servers.
        </div>
        <div className="ms-2">
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent's consent before We collect and use that
          information.
        </div>
        <div className="text-xl font-bold">Links to Other Websites</div>
        <div className="ms-2">
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </div>
        <div className="ms-2">
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </div>
        <div className="text-xl font-bold">Changes to this Privacy Policy</div>
        <div className="ms-2">
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </div>
        <div className="ms-2">
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the
          &quot;Last updated&quot; date at the top of this Privacy Policy.
        </div>
        <div className="ms-2">
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </div>
        <div className="text-xl font-bold">Contact Us</div>
        <div className="ms-2">
          If you have any questions about this Privacy Policy, You can contact
          us:
        </div>
        <div className="ms-2">
          <li>By email: info@solutionsunified.com</li>
        </div>
      </div>
    </MainLayout>
  );
};

export default PrivacyPolicyPage;
