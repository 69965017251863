import React from "react";
import { PeopleIcon, ShieldCheckIcon, LockIcon } from "@primer/octicons-react";
import MainLayout from "../layouts/MainLayout";

const DOC_GROUPS = [
  {
    icon: PeopleIcon,
    title: "Customers",
    description: "Read these documents to learn about using our applications",
    links: [
      { title: "Terms of Service", url: "/terms-of-service" },
      { title: "Cookie Policy", url: "/cookie-policy" },
    ],
  },
  {
    icon: LockIcon,
    title: "Privacy and Security",
    description:
      "Your privacy and data security are essential to us. Read these documents to learn about how we serve you",
    links: [
      { title: "Privacy Policy", url: "/privacy-policy" },
      { title: "ISO 27001 Certificate", url: "/iso-27001-certificate" },
    ],
  },
  {
    icon: ShieldCheckIcon,
    title: "Quality",
    description:
      "We enhance your quality compliance. We maintain our own compliance as well",
    links: [{ title: "ISO 9001 Certificate", url: "/iso-9001-certificate" }],
  },
];

const LegalDocsPage: React.FC = () => {
  return (
    <MainLayout>
      {/* HERO */}
      <div className="flex flex-col justify-center items-center mt-10 mb-10 px-4 md:px-12 lg:px-24 py-12 mx-4 md:mx-0">
        <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-6 text-center">
          LEGAL DOCUMENTS
        </h1>
        <p className="text-gray-700 text-center mb-4">
          Whether you are a current customer, prospect customer, or visitor to
          Solutions Unified, we believe that transparency is important.
          <br className="hidden md:block" />
          To make it easy to find the information you’re looking for, we’ve
          organized our legal documents in one place.
        </p>
      </div>

      {/* DOCS */}
      <div className="mb-40 px-4 lg:px-8 gap-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {DOC_GROUPS.map((item, index) => (
          <div
            key={index}
            className="bg-white text-center p-6 rounded-lg shadow-lg flex-1"
          >
            <item.icon size={48} className="text-gray-900 mb-4 inline-block" />
            <h3 className="font-bold mb-4 text-xl text-gray-900">
              {item.title}
            </h3>
            <p className="text-gray-700 mb-6">{item.description}</p>
            {item.links.map((link, index) => (
              <a
                key={index}
                href={link.url}
                className="text-[#0274bf] hover:underline block mt-2"
              >
                {link.title}
              </a>
            ))}
          </div>
        ))}
      </div>
    </MainLayout>
  );
};

export default LegalDocsPage;
