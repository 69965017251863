import Home from "../pages/HomePage";
import Products from "../pages/ProductsPage";
import Company from "../pages/CompanyPage";
import Contact from "../pages/ContactPage";

export const directory = [
  { name: "Home", path: "/", page: Home },
  { name: "Products", path: "/products", page: Products },
  { name: "Company", path: "/company", page: Company },
  { name: "Contact", path: "/contact", page: Contact },
];
