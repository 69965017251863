import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { directory } from "./util/directory";
import NotFoundPage from "./pages/NotFoundPage";
import CookiesPage from "./pages/CookiesPage";
import TermsPage from "./pages/TermsPage";
import PrivacyPage from "./pages/PrivacyPage";
import LegalDocsPage from "./pages/LegalDocsPage";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {directory.map((route) => (
          <Route key={route.name} path={route.path} element={<route.page />} />
        ))}
        <Route path="/terms-of-service" element={<TermsPage />} />
        <Route path="/cookie-policy" element={<CookiesPage />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
        <Route path="/legal-documents" element={<LegalDocsPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;
