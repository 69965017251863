import React from "react";

import MainLayout from "../layouts/MainLayout";

import Card from "../components/base/Card";
import CardBody from "../components/base/CardBody";

const NotFoundPage: React.FC = () => {
  return (
    <MainLayout>
      <div className="container">
        <Card>
          <CardBody>
            <h1 className="text-2xl font-medium">404</h1>
            <p className="text-lg font-medium">Page not found</p>
          </CardBody>
        </Card>
      </div>
    </MainLayout>
  );
};

export default NotFoundPage;
