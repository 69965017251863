import React from "react";
import MainLayout from "../layouts/MainLayout";

const TermsPage: React.FC = () => {
  return (
    <MainLayout>
      <div className="flex flex-col gap-4">
        <div className="text-2xl font-bold">
          Cookie Policy for Solutions Unified
        </div>
        <div>
          This is the Cookie Policy for Solutions Unified, accessible from
          solutionsunified.com
        </div>
        <div className="text-xl font-bold">What Are Cookies</div>
        <div>
          As is common practice with almost all professional websites this site
          uses cookies, which are tiny files that are downloaded to your
          computer, to improve your experience. This page describes what
          information they gather, how we use it and why we sometimes need to
          store these cookies. We will also share how you can prevent these
          cookies from being stored however this may downgrade or 'break'
          certain elements of the sites functionality.
        </div>
        <div className="text-xl font-bold">How We Use Cookies</div>
        <div>
          We use cookies exclusively for essential performance only, and expire
          within 24 hours. In most cases there are no industry standard options
          for disabling cookies without completely disabling the functionality
          and features they add to this site. It is recommended that you leave
          on all cookies if you are not sure whether you need them or not in
          case they are used to provide a service that you use.
        </div>
        <div className="text-xl font-bold">Disabling Cookies</div>
        <div>
          You can prevent the setting of cookies by adjusting the settings on
          your browser (see your browser Help for how to do this). Be aware that
          disabling cookies will affect the functionality of this and many other
          websites that you visit. Disabling cookies will usually result in also
          disabling certain functionality and features of the site. Therefore,
          it is recommended that you do not disable cookies.
        </div>
        <div className="text-xl font-bold">The Cookies We Set</div>
        <ul>
          <li className="font-bold">Login related cookies</li>
          <div>
            We use cookies when you are logged in so that we can remember this
            event. This prevents you from having to log in every single time you
            visit a new page. These cookies are typically removed or cleared
            when you log out to ensure that you can only access restricted
            features and areas when logged in.
          </div>
          <li className="font-bold">Forms related cookies</li>
          <div>
            When you submit data through a form such as those found on contact
            pages or comment forms cookies may be set to remember your user
            details for future correspondence.
          </div>
        </ul>
        <div className="text-xl font-bold">More Information</div>
        <div>
          For more information on cookies, you can contact us by email at
          info@solutionsunified.com
        </div>
      </div>
    </MainLayout>
  );
};

export default TermsPage;
