import React from "react";
import { PeopleIcon, MailIcon } from "@primer/octicons-react";
import MainLayout from "../layouts/MainLayout";

const Contact: React.FC = () => {
  return (
    <MainLayout>
      {/* HERO for cards */}
      <div className="flex flex-col justify-center items-center mt-10 px-4 sm:px-12 py-12 mx-4 sm:mx-0">
        <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-6 text-center">
          Contact Information
        </h1>
        <p className="text-gray-700 text-center">
          Find various ways to get in touch with us.
        </p>
      </div>
      {/* cards row */}
      <div className="mb-20 flex flex-col sm:flex-row justify-center items-start w-full space-y-6 sm:space-y-0 sm:space-x-10">
        {/* email */}
        <div className="bg-white p-6 rounded-lg shadow-lg h-48 w-full sm:w-80 flex flex-col">
          <div className="flex flex-row items-center mb-2">
            <MailIcon size={24} className="text-[#0274bf] mr-2" />
            <h3 className="text-lg text-gray-900 font-semibold">Email</h3>
          </div>
          <p className="text-gray-600">
            <a
              href="mailto:contact@solutionsunified.com"
              className="text-blue-500 hover:underline"
            >
              contact@solutionsunified.com
            </a>
          </p>
        </div>
        {/* phone */}
        <div className="bg-white p-6 rounded-lg shadow-lg h-48 w-full sm:w-80 flex flex-col justify-between">
          <div>
            <div className="flex flex-row items-center mb-2">
              <PeopleIcon size={24} className="text-[#0274bf] mr-2" />
              <h3 className="text-lg text-gray-900 font-semibold">Phone</h3>
            </div>
            <p className="text-gray-600">1-xxx-xxx-xxx</p>
          </div>
          <hr className="my-2" />
          <div>
            <p className="text-gray-600">
              Hours of Operation <br /> 6am – 6pm PT on weekdays
              <br /> 6am – 4pm PT on weekends
            </p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Contact;
