import React from "react";

import MainLayout from "../layouts/MainLayout";

const CompanyPage: React.FC = () => {
  return (
    <MainLayout>
      <div>
        <h2>Company Page</h2>
        <p>
          Welcome to our company page. Here you can information about our
          company.
        </p>
      </div>
    </MainLayout>
  );
};

export default CompanyPage;
