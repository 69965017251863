import React from "react";

import MainLayout from "../layouts/MainLayout";
const ProductsPage: React.FC = () => {
  return (
    <MainLayout>
      <div>
        <h2>Products Page</h2>
        <p>Welcome to our products page. Here you can find our products.</p>
      </div>
    </MainLayout>
  );
};

export default ProductsPage;
