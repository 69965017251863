import React, { ReactNode } from "react";

interface CardBodyProps {
  children: ReactNode;
  className?: string;
}

const CardBody: React.FC<CardBodyProps> = ({ children, className = "" }) => {
  return <div className={`p-3 ${className}`}>{children}</div>;
};

export default CardBody;
