import React from "react";

const Copyright = () => {
  const currentDate = new Date().getFullYear();

  return (
    <span>© {currentDate} Solutions Unified LLC. All rights reserved.</span>
  );
};

export default Copyright;
